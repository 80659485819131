/*
SPURR
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jan 12, 2022
UPDATE DATE: Jan 12, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: Spurr | Drive Value Build Loyalty
PALETTE:    1. #00E900 / rgb(0, 233, 0) (Pure Lime Green / Green)
            2. #009C00 / rgb(0, 156, 0) (Dark Lime Green / Japanese Laurel)
*/

@import './variable.scss';

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
    font-family: 'SourceSansPro';
    src: url('assets/font/SourceSansPro-Regular.woff2') format('woff2'),
         url('assets/font/SourceSansPro-Regular.woff') format('woff'),
         url('assets/font/SourceSansPro-Regular.ttf') format('ttf'),
         url('assets/font/SourceSansPro-Regular.eot') format('eot'),
         url('assets/font/SourceSansPro-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'SourceSansPro';
    src: url('assets/font/SourceSansPro-Semibold.woff2') format('woff2'),
         url('assets/font/SourceSansPro-Semibold.woff') format('woff'),
         url('assets/font/SourceSansPro-Semibold.ttf') format('ttf'),
         url('assets/font/SourceSansPro-Semibold.eot') format('eot'),
         url('assets/font/SourceSansPro-Semibold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'SourceSansPro';
    src: url('assets/font/SourceSansPro-Bold.woff2') format('woff2'),
         url('assets/font/SourceSansPro-Bold.woff') format('woff'),
         url('assets/font/SourceSansPro-Bold.ttf') format('ttf'),
         url('assets/font/SourceSansPro-Bold.eot') format('eot'),
         url('assets/font/SourceSansPro-Bold.svg') format('svg');
    font-weight: bold;
    font-style: normal;
}

html{
    -webkit-text-size-adjust: 100%;
}

body{
    background: #FAFAFA;
    color: $primaryText;
    font-size: 12px;
    line-height: 12px;
    font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
    margin: 0;
    font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;

    a{
        text-decoration: none;
        color: $primaryColor;
        @extend %transition;
    }
}

svg{
    display: inline-block;
    vertical-align: middle;
}

input,
textarea,
select,
button{
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
    padding: 0;
    margin: 0;
    color: $primaryText;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    @extend %transition;

    &:focus{
        outline: none;
    }
}

input[disabled]{
    opacity: .5;
    pointer-events: none;
}

input[type="radio"]{
    appearance: radio;
    -moz-appearance: radio;
    -webkit-appearance: radio;
}

button{
    cursor: pointer;

    &:active{
        color: currentColor;
    }

    &.clear{
        background: transparent;
        border: none;
        font-size: 12px;
        line-height: 12px;
        height: auto;
        padding: 0;
        margin: 0;
    }

    *{
        pointer-events: none;
    }
}

section{
    position: relative;
    padding: 20px;
    max-width: $maxWidth;
    margin: 0 auto;
    box-sizing: border-box;

    &.nopad{
        padding: 0;
    }
}

.btn-primary{
    background: $primaryColor;
    border: 1px solid $primaryColor;
    box-sizing: border-box;

    &.btn-error{
        background: $errorColor;
        border: 1px solid $errorColor;
    }

    &:hover{
        background: $secondaryColor;
        border: 1px solid $secondaryColor;
    }

    &:disabled,
    &[disabled]{
        pointer-events: none;
        background: #CCC;
        border: 1px solid #CCC;
    }

    svg{
        margin: -3.5px 0 0 2.5px;

        &.icon-big{
            width: 25px;
            height: 25px;
            margin: -2.5px 0 0 0;
        }
    }
}

ul.floating{
    overflow: auto;
    margin: -10px;

    > li{
        float: left;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;

        &.lay2{
            width: 50%;
        }
    }
}

.forming{
    label{
        display: block;
        margin: 0 0 10px 0;
        color: $secondaryText;
    }

    input,
    textarea{
        border: none;
        background: #F5F5F5;
        border-radius: $borderRadius;
        font-size: 14px;
        padding: 10px;
        height: 40px;

        &:hover{
            box-shadow: 0 0 5px $primaryColor;
        }

        &:focus{
            box-shadow: 0 0 10px $primaryColor;
        }
    }

    textarea{
        resize: none;
        height: 60px;
    }
}

.react-select{
    .rs__control{
        border: none !important;
        background: #F5F5F5;
        border-radius: $borderRadius;
        box-shadow: none;
        @extend %transition;

        &:hover{
            border: none !important;
            box-shadow: 0 0 5px $primaryColor;
        }

        // &.rs__control--is-focused.rs__control--menu-is-open{
        //     border: none;
        // }
    }

    .rs__value-container{
        padding: 0 0 0 10px;
    }

    .rs__input-container{
        padding: 0;
        margin: 0;
    }

    .rs__single-value{
        font-size: 14px;
    }

    .rs__indicator-separator{
        display: none;
    }

    .rs__input{
        box-shadow: none;

        &:hover{
            box-shadow: none;
        }

        &:focus{
            box-shadow: none;
        }
    }
}

body{
    .rs__menu-portal{
        z-index: 9999;

        .rs__menu{
            .rs__menu-list{
                .rs__option{
                    font-size: 12px;
                    line-height: 16px;
                    @extend %transition;
                }

                .rs__option--is-focused{

                }

                .rs__option--is-selected{
                    background: $primaryColor;
                }
            }
        }
    }
}

.powered{
    opacity: .75;
    color: #000;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    margin: 30px 0 10px 0;

    img{
        display: inline-block;
        width: 100px;
        height: 18px;
        object-fit: contain;
        margin: 0 0 0 5px;
    }
}

.popup-content {
    position: absolute !important;
    background: $transBg;
    width: 100%;
    max-width: $maxWidth;
    padding: 20px;
    border-radius: $borderRadius $borderRadius 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    box-sizing: border-box;
    overflow: hidden;
    @extend %blur;
}

ul.wrapbtn{
    background: $transBg;
    position: fixed;
    padding: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: $maxWidth;
    margin: 0 auto;
    box-sizing: border-box;
    @extend %blur;
    box-shadow: $boxShadowInv;

    > li{
        width: 65%;

        &:first-child{
            width: 35%;
        }

        &.single{
            width: 100%;
        }

        > .btn-primary{
            display: block;
            text-align: center;
            color: #FFF;
            border-radius: $borderRadius;
            height: 35px;
            line-height: 30px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;

            strong{
                display: inline-block;
                vertical-align: middle;
                margin: -7px 0 0 0;
                font-size: 12px;
                line-height: 11px;
                text-transform: none;
                text-transform: lowercase;
            }
        }

        > ul.total{
            margin: -5px -5px -10px -5px;

            > li{
                width: 50%;
                font-size: 18px;
                line-height: 18px;
                padding: 5px;

                &:last-child{
                    text-align: right;
                    font-weight: bold;
                }
            }
        }
    }
}

// .popup-arrow {
//     color: rgb(255, 255, 255);
// }

// [role='tooltip'].popup-content {
//     width: 200px;
//     box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
//     border-radius: 5px;
// }

.popup-overlay {
    // pointer-events: none !important;
    background: rgba(0, 0, 0, .5);
    @extend %blur;
}

// [data-popup='tooltip'].popup-overlay {
//     background: transparent;
// }

// .popup-arrow {
//     filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
//     color: #fff;
//     stroke-width: 2px;
//     stroke: #d7d7d7;
//     stroke-dasharray: 30px;
//     stroke-dashoffset: -54px;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
// }

header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $transBg;
    padding: 10px 0;
    box-shadow: $boxShadow;
    z-index: 9;
    @extend %blur;
    @extend %transition;

    &.grad{
        background: transparent;
        box-shadow: none;
        -webkit-backdrop-filter: blur(0);
        backdrop-filter: blur(0);
    }

    section{
        padding: 0 20px;
    }

    .headerlogo{
        display: block;
        position: relative;
        width: 140px;
        margin: 0 auto;
        height: 40px;
        text-align: center;
        font-size: 20px;
        line-height: 40px;
        font-weight: 600;
        @extend %ellipsis;

        &.oneicon{
            width: 254px;
        }

        img{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        > svg{
            font-size: 24px;
            margin: -2.5px 4px 0 0;
        }
    }
    
    .resto-name{
        border-top: $primaryBorder;
        margin: 10px 0 0 0;
        padding: 5px 0 0 0;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-weight: 600;
        @extend %ellipsis;

        span{
            display: block;
            font-size: 12px;
            line-height: 16px;
            color: $secondaryText;
            font-weight: normal;
            @extend %ellipsis;
        }
    }

    .headersearch{
        position: relative;
        margin: 0 0 0 45px;

        input{
            padding: 10px 35px;
        }

        > button{
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 10px;
            width: 18px;
            height: 18px;
            border-radius: 18px;
            background: $secondaryText;
            color: #FFF;
            font-size: 16px;
            
            &:hover{
                background: $primaryText;
            }
        }

        > svg{
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 10px;
            font-size: 20px;
            color: $secondaryText;
        }
    }

    ul.nav-head{
        position: absolute;

        &.nav-left{
            left: 20px;
        }
    
        &.nav-right{
            right: 20px;
        }

        > li{

            a, button{
                position: relative;
                display: block;
                color: $primaryColor;
                font-size: 24px;

                &.bigic,
                &.bigic{
                    font-size: 35px;
                }

                span{
                    position: absolute;
                    top: 3px;
                    left: -5px;
                    background: $errorColor;
                    color: #FFF;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 11px;
                    padding: 1.5px 5px 3.5px 5px;
                    min-width: 16px;
                    box-sizing: border-box;
                    text-align: center;
                    border-radius: 100px;
                }

                svg{
                    height: 40px;
                }
            }
        }
    }

    .wrapcate{
        margin: 10px -20px -10px -20px;

        &.catelite{
            > ul{
                > li{
                    a{
                        span{
                            padding: 24% 0;
                        }
                    }
                }
            }
        }

        ul{
            margin: -2.5px 0;
            padding: 0 7.5px 7.5px 7.5px;
            overflow: scroll;
            white-space: nowrap;

            > li{
                display: inline-block;
                vertical-align: top;
                padding: 2.5px;
                width: 23%;
                
                a{
                    display: block;
                    text-align: center;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                    border-radius: $borderRadius;
                    box-sizing: border-box;

                    &:before{
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        object-fit: cover;
                        padding: 0;
                        margin: 0;
                        background: #000;
                        z-index: 1;
                        opacity: .2;
                        border-radius: $borderRadius;
                        @extend %transition;
                    }
                    
                    &:hover:before{
                        opacity: .5;
                    }
                    
                    &.ison:before{
                        background: $primaryColor;
                        opacity: .75;
                    }

                    span{
                        display: block !important;
                        height: 0;
                        padding: 28% 0;
                        @extend %transition;
                    }

                    strong{
                        position: absolute;
                        left: 10px;
                        right: 10px;
                        top: 50%;
                        transform: translate(0, -50%);
                        z-index: 2;
                        color: #FFF;
                        font-weight: bold;
                        
                        display: block;
                        display: -moz-box;
                        display: -webkit-box;
                        max-height: 27px;
                        line-clamp: 2;
                        box-orient: vertical;
                        -moz-line-clamp: 2;
                        -moz-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                        overflow: hidden;
                        text-transform: uppercase;
                    }
                    
                    img{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        object-fit: cover;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.pad-header{
    height: 60px;

    &.pad-cate{
        height: 116px;
    }

    &.pad-info{
        height: 112px;
    }
}

ul.table-info{
    border-radius: $borderRadius;
    padding: 7.5px 12.5px;
    background: $infoColor;
    margin: 0 0 5px 0;
    text-align: center;

    li{
        display: inline-block;
        padding: 7.5px;
        font-size: 14px;
        line-height: 18px;

        strong{
            font-weight: 600;
        }

        svg{
            margin: -3px 4px 0 0;
            font-size: 17px;
        }
    }
}

.btnbottom{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px 0;
    background: $transBg;
    box-shadow: $boxShadowInv;
    z-index: 2;
    @extend %blur;

    section{
        padding: 0 20px;
    }

    a,
    button{
        display: block;
        color: #FFF;
        height: 40px;
        line-height: 35px;
        border-radius: $borderRadius;
        font-size: 16px;
        padding: 0 10px;

        ul.floating{

            li{
                width: 50%;
                text-align: left;
                font-weight: 600;

                &:last-child{
                    text-align: right;
                    font-weight: bold;
    
                    svg{
                        width: 25px;
                        height: 25px;
                        margin: -3px 0 0 0;
                    }
                }

                svg{
                    width: 20px;
                    height: 20px;
                    margin: -3px 5px 0 0;
                }
            }
        }
    }
}

ul.qty{
    margin: 0 auto;

    li{
        width: 33.33%;
        text-align: center;
        padding: 0;
        font-size: 12px;
        font-weight: bold;

        &:nth-child(2){
            background: $secondaryColor;
            color: #FFF;
            line-height: 35px;
            font-size: 16px;
        }
    
        &:first-child{
            button{
                border-radius: $borderRadius 0 0 $borderRadius;
            }
        }
    
        &:last-child{
            button{
                border-radius: 0 $borderRadius $borderRadius 0;
            }
        }

        button{
            height: 35px;
            color: #FFF;
            font-size: 18px;

            svg{
                margin: -3px 0 0 0;
            }
        }
    }
}

.padbottom{
    height: 80px;
}

.padbottom.oncart{
    height: 108px;
}

.splashscreen{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00c500+0,007401+100 */
    background: $gradientTop; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  $gradientTop 0%, $gradientBottom 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  $gradientTop 0%,$gradientBottom 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  $gradientTop 0%,$gradientBottom 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradientTop', endColorstr='$gradientBottom',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    .splashwrap{
        max-width: 250px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0 auto;
        color: #FFF;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-size: 14px;
        line-height: 14px;

        img{
            display: block;
            margin: 0 0 10px 0;
        }
    }

    .powered{
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        color: #FFF;
        margin: 0;
    }
}

ul.listmulti{
    padding: 0 0 0 20px;

    > li{
        position: relative;
        padding: 20px 20px 20px 36px;
        border-bottom: $primaryBorder;

        &:last-child{
            border-bottom: none;
        }

        > ul.multiitem{
            > li{
                font-size: 16px;
                line-height: 20px;
                width: 55%;

                &:last-child{
                    width: 45%;
                    font-weight: bold;
                    text-align: right;
                }
            }

            &.single{
                > li,
                > li:last-child{
                    width: 100%;
                    font-weight: normal;
                    text-align: left;
                }
            }
        }

        ul.multicustom{
            margin: 2.5px 0;

            > li{
                padding: 2.5px 0;
                
                > ul{
                    margin: -2.5px;

                    > li{
                        display: inline-block;
                        padding: 2.5px;

                        &:first-child{
                            color: $secondaryText;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        > ul.multicombo{
            margin: -15px 0 -5px 0;

            > li{
                padding: 15px 0;
                border-bottom: $primaryBorder;

                &:last-child{
                    border-bottom: none;
                }
                
                > ul{
                    margin: -2.5px;

                    > li{
                        padding: 2.5px;

                        &:first-child{
                            color: $secondaryText;
                            font-weight: bold;
                        }

                        > ul{
                            margin: 0 0 -5px 0;

                            > li{
                                position: relative;
                                padding: 5px 0 5px 33px;

                                > ul.multiitem{
                                    margin: -2.5px;

                                    > li{
                                        padding: 2.5px;
                                        width: 55%;
                                        font-size: 14px;
                                        line-height: 18px;
                                        
                                        &:last-child{
                                            width: 45%;
                                            text-align: right;
                                            font-weight: bold;
                                        }
                                    }
                                }

                                .multiqty{
                                    top: 5px;
                                    font-size: 11px;
                                    line-height: 11px;
                                    padding: 2px 4px 3px 4px;
                                    border-radius: $borderRadius - 1;
                                }

                                ul.multiact{
                                    margin: -2.5px;
                                    // margin: 0 -5px -5px -5px;
                        
                                    > li{
                                        padding: 2.5px;
                                        width: auto;
                        
                                        button{
                                            font-size: 12px;
                                            line-height: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .multinote{
            color: $secondaryText;
            font-size: 12px;
            line-height: 16px;
            margin: 5px 0 0 0;
        }

        .multiqty{
            position: absolute;
            left: 0;
            top: 20px;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            padding: 3px 5px 4px 5px;
            border: 1px solid $primaryColor;
            border-radius: $borderRadius;
        }

        ul.multiact{
            margin: 0 -5px -5px -5px;

            > li{
                width: auto;
                padding: 5px;

                button{
                    width: auto;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: left;
                    font-weight: 600;
                    color: $primaryColor;
                }
            }
        }
    }
}

ul.menulist{
    margin: -20px -20px 0 -20px;
}

ul.menulist.oncart{
    border-top: $primaryBorder;
    margin: -20px -20px -20px 0;
}

ul.menulist > li{
    padding: 0 0 0 20px;
    font-size: 22px;
    line-height: 26px;
}

ul.menulist.oncart > li{
    padding: 0;
}

ul.menulist > li > div{
    padding: 10px 20px 10px 0;
    border-bottom: $primaryBorder;
}

ul.menulist.nobottom > li:last-child > div{
    border-bottom: none;
}

ul.menulist.oncart > li > div{
    padding: 20px 0;
    margin: 0 0 0 -20px;
}

ul.menulist.oncart > li > div > span{
    display: block;
    color: $secondaryText;
    padding: 0 0 0 20px;
    font-size: 14px;
    line-height: 18px;
}

ul.menulist > li > div > ul{
    // margin: 10px 0 5px 0;
    margin: 0 -5px;
    padding: 5px 0;
}

ul.menulist.oncart > li > div > ul{
    white-space: nowrap;
    overflow-x: scroll;
    padding: 5px 15px 15px 15px;
    margin: 0 0 -20px 0;
}

// ul.menulist > li > div > ul.foryou{
//     margin: 0 -5px;
//     padding: 5px 0;
// }

ul.menulist > li > div > ul > li{
    position: relative;
    padding: 5px;
}

ul.menulist.oncart > li > div > ul > li{
    display: inline-block;
    width: 65%;
}

ul.menulist > li > div > ul.foryou > li{
    width: 50%;
}

ul.menulist > li > div > ul > li > button.clear{
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    overflow: auto;
    background: #FFF;
    padding-bottom: 22.5px;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    box-sizing: border-box;
    overflow: hidden;
}

ul.menulist > li > div > ul.foryou > li > button.clear{
    padding-bottom: 0;
}

ul.menulist > li > div > ul > li > button.clear.added{
    border-left: 5px solid $primaryColor;
}

ul.menulist > li > div > ul > li > button.clear.noimg{
    padding-bottom: 0;
}

ul.menulist.oncart > li > div > ul > li > button.clear.noimg{
    padding-bottom: 22.5px;
}

ul.menulist > li > div > ul > li > button.sold.clear{
    background: rgba(255, 255, 255, .5);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
}

ul.menulist > li > div > ul > li > button .wrapimage{
    position: relative;
    float: right;
    width: 24.1%;
    box-sizing: border-box;
}

ul.menulist.oncart > li > div > ul > li > button .wrapimage{
    width: 36%;
}

ul.menulist > li > div > ul.foryou > li > button .wrapimage{
    width: 100%;
    padding: 0 0 0 15px;
    margin: 0 0 20px 0;
    @extend %transition;
}

ul.menulist > li > div > ul.foryou > li > button.added .wrapimage{
    padding: 0 0 0 10px;
}

ul.menulist > li > div > ul > li > button .wrapimage span.lazy-load-image-background,
ul.menulist > li > div > ul > li > button .wrapimage .noimg{
    display: block !important;
    height: 0;
    padding: 50% 0;
}

ul.menulist > li > div > ul.foryou > li > button .wrapimage span.lazy-load-image-background{
    position: relative;
}

ul.menulist > li > div > ul > li > button .wrapimage span.lazy-load-image-background img{
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 0 0 $borderRadius;
    object-fit: cover;
}

ul.menulist > li > div > ul > li > button.sold .wrapimage span.lazy-load-image-background img{
    filter: saturate(0);
    opacity: .5;
}

ul.menulist > li > div > ul > li > button .wrapimage .btn-primary{
    position: absolute;
    border: none;
    text-align: center;
    line-height: 25px;
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    // width: 60px;
    bottom: 0;
    transform: translate(0, 50%);
    right: 10px;
    left: 10px;
    border-radius: $borderRadius;
    @extend %transition;
}

ul.menulist > li > div > ul.foryou > li > button .wrapimage .btn-primary{
    left: 25px;
}

ul.menulist > li > div > ul.foryou > li > button.added .wrapimage .btn-primary{
    left: 20px;
}

ul.menulist > li > div > ul > li > button.noimg .wrapimage .btn-primary{
    bottom: auto;
    top: 50%;
    transform: translate(0, -50%);
}

ul.menulist > li > div > ul > li > button.noimg.sold .wrapimage .btn-primary{
    margin: 10px 0 0 0;
}

ul.menulist > li > div > ul > li > button.sold .soldtext{
    position: absolute;
    right: 10px;
    width: 60px;
    top: 0;
    background: $soldColor;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    color: #FFF;
    z-index: 1;
    text-align: center;
    padding: 5px 7.5px;
    box-sizing: border-box;
    border-radius: 0 0 $borderRadius $borderRadius;
    @extend %blur;
}

ul.menulist > li > div > ul.foryou > li > button.sold .soldtext{
    left: 25px;
    width: auto;
}

ul.menulist > li > div > ul > li > button.sold.noimg .soldtext{
    top: 0;
    transform: translate(0, 0);
}

ul.menulist > li > div > ul > li > button > ul{
    margin: 12.5px 92.5px 12.5px 12.5px;
    @extend %transition;
}

ul.menulist > li > div > ul > li > button.added > ul{
    margin: 12.5px 92.5px 12.5px 7.5px;
}

ul.menulist > li > div > ul.foryou > li > button > ul{
    margin: 12.5px;
}

ul.menulist > li > div > ul > li > button.sold > ul{
    opacity: .5;
}

ul.menulist > li > div > ul > li > button > ul > li{
    padding: 2.5px;
    @extend %ellipsis;
}

ul.menulist > li > div > ul > li > button > ul > li.thename{
    font-size: 15px;
    line-height: 19px;

    display: block;
    display: -moz-box;
    display: -webkit-box;
    max-height: 38px;
    line-clamp: 2;
    box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

ul.menulist > li > div > ul.foryou > li > button > ul > li.thename{
    height: 38px;
}

ul.menulist > li > div > ul > li > button > ul > li.theprice{
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
}

ul.menulist > li > div > ul > li > button > ul > li.theadd{
    margin: 5px 0 -22.5px -15px;
}

ul.menulist > li > div > ul.foryou > li > button > ul > li.theadd{
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px 0 0 -2.5px;
}

ul.menulist > li > div > ul > li > button.noimg > ul > li.theadd{
    margin-bottom: 0;
}

ul.menulist > li > div > ul > li > button > ul > li.theadd div{
    display: inline-block;
    background: $primaryColor;
    color: #FFF;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    margin: 2.5px 0 0 0;
    padding: 3.5px 6px 5px 5px;
    border-radius: 0 $borderRadius $borderRadius 0;
}

ul.menulist > li > div > ul > li > button > ul > li.theadd div svg{
    font-size: 14px;
    margin: -5px 0 -2px 3px;
}

ul.menulist > li > div > ul > li > button > ul.floating.thetag{
    margin: -2.5px 92.5px -12.5px 12.5px;
}

ul.menulist > li > div > ul > li > button.added > ul.floating.thetag{
    margin: -2.5px 92.5px -12.5px 7.5px;
}

ul.menulist > li > div > ul.foryou > li > button > ul.floating.thetag{
    position: absolute;
    // right: 0;
    top: 15px;
    margin: -2.5px;
}

ul.menulist > li > div > ul.foryou > li > button.sold > ul.floating.thetag{
    top: 30px;
}

ul.menulist > li > div > ul.foryou > li > button.added > ul.floating.thetag{
    top: 40px;
}

ul.menulist > li > div > ul > li > button > ul.floating.thetag li{
    width: auto;
    padding: 2.5px;
    font-size: 14px;
}

ul.menulist > li > div > ul.foryou > li > button > ul.floating.thetag li{
    width: 100%;
    // text-align: right;
}

ul.menulist > li > div > ul > li > button > ul.floating.thetag li svg{
    border: $primaryBorder;
    border-top: none;
    padding: 4px 4.5px;
    border-radius: 0 0 $borderRadius $borderRadius;
    background: $transBg;
    @extend %blur;
}

ul.menulist > li > div > ul.foryou > li > button > ul.floating.thetag li svg{
    border: $primaryBorder;
    border-left: none;
    border-radius: 0 $borderRadius $borderRadius 0;
    padding: 4.5px;
}

ul.itemtag{
    padding: 0 20px;
    margin: -5px -5px -10px -5px;
    
    > li{
        padding: 5px;
        width: auto;

        > div{
            border: 1px solid #E6E6E6;
            border-top: none;
            border-radius: 0 0 $borderRadius $borderRadius;
            padding: 7.5px 10px 7.5px 7.5px;
            text-transform: capitalize;
            font-size: 12px;
            font-weight: 600;
            color: $secondaryText;

            > svg{
                margin: -2px 5px 0 0;
                font-size: 16px;
            }
        }
    }
}

.modalmulti ul.itemtag{
    margin: -22.5px -2.5px 7.5px -2.5px;
    padding: 0;

    li{
        padding: 2.5px;

        div{
            padding: 5px;

            svg{
                margin: -2px 0 0 0;
                font-size: 14px;
            }
        }
    }
}

.modalitem{
    overflow-y: scroll;
    overflow-x: hidden;
    margin: -20px;
    padding: 0 0 75px 0;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    border-radius: $borderRadius $borderRadius 0 0;

    .itemdetail{
        .lazy-load-image-background{
            display: block !important;
            position: relative;
            height: 0;
            padding: 50%;

            img{
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                &.imgsold{
                    filter: saturate(0);
                }
            }
        }

        ul.itemtext{
            padding: 15px 17.5px 17.5px 17.5px;

            li{
                padding: 2.5px;

                &.name{
                    font-size: 22px;
                    line-height: 26px;
                }
    
                &.desc{
                    font-size: 14px;
                    line-height: 18px;
                    color: $secondaryText;
                }
    
                &.price{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 16px;
                    padding-top: 5px;
                }
            }
        }

        .sold{
            position: absolute;
            right: 60px;
            left: 20px;
            // width: 60px;
            top: 0;
            background: $soldColor;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 14px;
            color: #FFF;
            z-index: 1;
            text-align: center;
            padding: 13px 20px;
            box-sizing: border-box;
            border-radius: 0 0 $borderRadius $borderRadius;
            @extend %blur;
        }
    }

    ul.skusel{
        padding: 15px 20px 15px 0;
        margin: 0 0 0 20px;
        border-top: $primaryBorder;

        > li{
            padding: 5px 0;

            &.skutit{
                color: $secondaryText;
                line-height: 18px;
            }

            &.skuinfo{
                color: $secondaryText;
                font-size: 12px;
                line-height: 16px;
                background: $infoColor;
                padding: 5px;
                border-radius: $borderRadius;
                text-align: center;
                margin: 10px 0 0 0;
            }

            &.hidden{
                height: 0;
                padding: 0;
                overflow: hidden;
            }

            > button{
                position: relative;
                padding: 0 0 0 30px;

                &:before,
                &:after{
                    content: '';
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    border: 1px solid #808080;
                    box-sizing: border-box;
                    @extend %transition;
                }

                &:after{
                    opacity: 0;
                    left: 4px;
                    width: 12px;
                    height: 12px;
                    background: $primaryColor;
                    border: 1px solid $primaryColor;
                }

                &.ison:after{
                    opacity: 1;
                }

                > ul{
                    > li{
                        width: 35%;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: right;
                        font-weight: bold;

                        &:first-child{
                            width: 65%;
                            text-align: left;
                            font-weight: normal;
                        }

                        &.noprice{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    ul.customsel{
        padding: 0 0 0 20px;

        > li{
            border-top: $primaryBorder;
            padding: 20px 0;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;

            > span{
                display: block;
                font-size: 12px;
                line-height: 16px;
                color: $secondaryText;
                margin: 2.5px 0 0 0;
                font-weight: normal;
            }

            > .customstat{
                float: right;
                color: #FFF;
                background: #999;
                margin: 0 20px 0 0;
                font-size: 12px;
                line-height: 12px;
                font-weight: normal;
                padding: 4px 5px;
                border-radius: 5px;
                @extend %transition;
            }

            > .customstat.done{
                background: $primaryColor;
            }

            > svg{
                color: $primaryColor;
                font-size: 24px;
                margin: -10px 0 -5px 0;
            }

            > ul{
                margin: 5px 0 -5px 0;
                font-weight: normal;

                > li{
                    padding: 5px 20px 5px 0;
                    font-size: 14px;
                    line-height: 18px;

                    > button{
                        position: relative;
                        padding: 0 0 0 30px;

                        &:before,
                        &:after{
                            content: '';
                            width: 20px;
                            height: 20px;
                            border-radius: 20px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            border: 1px solid #808080;
                            box-sizing: border-box;
                            @extend %transition;
                        }

                        &.check:before,
                        &.check:after{
                            border-radius: 5px;
                        }

                        &.check:after{
                            border-radius: 3px;
                        }

                        &:after{
                            opacity: 0;
                            left: 4px;
                            width: 12px;
                            height: 12px;
                            background: $primaryColor;
                            border: 1px solid $primaryColor;
                        }

                        &.ison:after{
                            opacity: 1;
                        }

                        > ul{
                            > li{
                                width: 35%;
                                text-align: right;
                                font-weight: bold;
                                font-size: 16px;
                                line-height: 20px;

                                &:first-child{
                                    width: 65%;
                                    text-align: left;
                                    font-weight: normal;
                                }
    
                                &.single{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .note{
        margin: 0 0 0 20px;
        padding: 20px 20px 20px 0;
        border-top: $primaryBorder;

        // label{

        // }

        // textarea{

        // }
    }
}

.modalmulti{
    overflow-y: scroll;
    margin: -20px;
    padding: 0 0 75px 0;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    .itemdetail{
        padding: 20px;
        box-shadow: $boxShadow;

        .lazy-load-image-background{
            display: block !important;
            float: left;
            position: relative;
            width: 57px;
            height: 57px;
            margin: 0 10px 0 0;
            border-radius: $borderRadius;
            overflow: hidden;

            img{
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        ul.itemtext{
            li{
                padding: 1px;

                &.name{
                    font-size: 18px;
                    line-height: 22px;
                }
    
                &.desc{
                    font-size: 12px;
                    line-height: 16px;
                    color: $secondaryText;
    
                    display: block;
                    display: -moz-box;
                    display: -webkit-box;
                    max-height: 36px;
                    line-clamp: 2;
                    box-orient: vertical;
                    -moz-line-clamp: 2;
                    -moz-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                    overflow: hidden;
                }
    
                &.price{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 14px;
                    padding-top: 5px;
                }
            }
        }
    }
}

@keyframes anvil {
    0% {
        opacity: 0;
    }
    1% {
        opacity: 0;
        max-height: 0;
    }
    100% {
        max-height: 70vh;
    }
}

button.clear.closepop{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    color: #FFF;
    width: 40px;
    height: 40px;
    font-size: 24px;
    border-radius: 0 $borderRadius 0 $borderRadius;
    background: rgba(0, 0, 0, .25);
    @extend %blur;

    &:hover{
        background: rgba(0, 0, 0, .5);
    }
}

ul.pricesum{
    border-top: $primaryBorder;
    margin: -20px -20px 0 0;
    padding: 15px 20px 0 0;
    
    > li{
        padding: 5px 0;
        
        > ul{
            margin: -5px;

            > li{
                padding: 5px;
                width: 50%;
                font-size: 16px;
                line-height: 16px;

                &:last-child{
                    text-align: right;
                    font-weight: bold;
                }
            }
        }
    }

    &.has-total{
        > li{
            > ul{
                > li{
                    font-size: 14px;
                    line-height: 14px;
                }
            }

            &:last-child{
                border-top: $primaryBorder;
                padding: 10px 0 0 0;
                margin: 5px 0 0 0;
                
                > ul{
                    > li{
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

// ul.searchtag{

//     > li{
//         float: none;
//         display: inline-block;
//         width: auto;
//         padding: 5px;

//         > button{
//             width: auto;
//             border: $primaryBorder;
//             // padding: 10px;
//             padding: 7.5px 10px 7.5px 7.5px;
//             border-radius: $borderRadius;
//             font-weight: 600;
//             text-transform: capitalize;

//             > div{

//                 > svg{
//                     margin: -2px 5px 0 0;
//                     font-size: 16px;
//                 }
//             }
//         }
//     }
// }

ul.list-order{
    margin: -20px -20px 0 0;

    > li{
        border-bottom: $primaryBorder;

        > a{
            display: block;
            position: relative;
            padding: 20px 50px 20px 0;
            color: $primaryText;

            > ul{
                > li{
                    width: 50%;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;

                    &:last-child{
                        text-align: right;
                    }

                    > label{
                        display: block;
                        font-size: 12px;
                        line-height: 16px;
                        color: $secondaryText;
                        font-weight: normal;
                    }
                }
            }

            > svg{
                color: #00E900;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 30px;
            }
        }
    }
}

ul.search-shortcut{
    margin: -20px -20px -20px 0;

    > li{
        border-bottom: $primaryBorder;
        padding: 20px 20px 20px 0;
        color: $secondaryText;

        &:last-child{
            border-bottom: none;
        }

        > ul{
            padding: 10px 0 0 0;
            margin: -5px;

            > li{
                float: none;
                display: inline-block;
                width: auto;
                padding: 5px;

                > button{
                    text-transform: capitalize;
                    padding: 6px 7.5px 7.5px 7.5px;
                    border: $primaryBorder;
                    border-radius: $borderRadius;
                    font-weight: 600;
                    background: #FFF;

                    svg{
                        margin: -7px 5px -5px 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.section-empty{
    text-align: center;
    font-size: 20px;
    line-height: 24px;

    svg{
        display: block;
        margin: 0 auto 5px auto;
        font-size: 64px;
        color: $secondaryText;
    }

    span{
        display: block;
        margin: 5px 0 0 0;
        font-size: 14px;
        line-height: 18px;
        color: $secondaryText;
    }
}

.error-content{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    max-width: $maxWidth;
    margin: 0 auto 0 auto;
    padding: 112px 20px 75px 20px;

    span{
        display: block;
        font-size: 14px;
        line-height: 18px;
        margin: 5px 0 0 0;
        color: $secondaryText;
    }

    svg{
        display: block;
        margin: 0 auto 5px auto;
        font-size: 64px;
        line-height: 64px;
        color: $errorColor;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){
    section,
    .popup-content{
        max-width: none;
        
        ul.wrapbtn{
            max-width: none;
        }
    }
    
    
}

// @media screen and (min-width: 1025px) and (max-width: 1600px){}

// @media screen and (min-width: 768px) and (max-width: 1024px){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

// @media screen and (max-width: 767px){}

// @media screen and (max-width: 767px) and (orientation: landscape){}

// @media screen and (max-width: 767px) and (orientation: portrait){}