/*
SPURR
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jan 12, 2022
UPDATE DATE: Jan 12, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: Spurr | Drive Value Build Loyalty
PALETTE:    1. #00E900 / rgb(0, 233, 0) (Pure Lime Green / Green)
            2. #009C00 / rgb(0, 156, 0) (Dark Lime Green / Japanese Laurel)
*/

$primaryColor: #00E900;
$secondaryColor: #009C00;
$soldColor: rgba(209, 0, 0, 0.5);
$infoColor: rgba(10, 204, 10, .1);
$errorColor: #D10000;
$successColor: #009C00;
$gradientTop: #00c500;
$gradientBottom: #007401;
$boxShadow: 0 3px 6px rgba(0, 0, 0, .1);
$boxShadowInv: 0 -3px 6px rgba(0, 0, 0, .1);
$borderRadius: 6px;
$primaryText: #505050;
$secondaryText: #808080;
$primaryBorder: 1px solid #E6E6E6;
$maxWidth: 375px;
// $maxWidth: 450px;
$transBg: rgba(255, 255, 255, .95);

%transition{
    transition: all 200ms ease-in-out !important;
    -moz-transition: all 200ms ease-in-out !important;
    -webkit-transition: all 200ms ease-in-out !important;
}

%blur{
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

%ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}